.fade-section-up {
    position: relative;
    opacity: 0;
    transform: translateY(15vh);
    visibility: hidden;
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-section-up.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;    
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.fade-section-in {
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: opacity 2s ease-out;
}

.fade-section-in.visible {
    opacity: 1;
    visibility: visible;
}