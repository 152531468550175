@media only screen and (min-width: 951px) {
    .footer {
        width: 100%;
        margin-top: 3em;
    }
    .links {
        display: flex;
        flex-direction: column;
    }
    .links a{
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
        color: rgba(255,255,255, 0.5);
    }

    .links a:hover {
        color: white;
    }

    .created-by {
        margin-top: 3em;
    }

    .intro-links { 
        display: none;
    }
}


@media only screen and (max-width: 950px) {
    .footer {
        display: none;
    }

    .intro-links {
        flex-direction: row;
        margin-top: 2em;
    }

    .intro-links a {
        margin-right: 2em;
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255,255,255, 0.5);
    }
    .links a:hover {
        color: white;
    }
}