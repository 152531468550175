@media only screen and (min-width: 951px){
    .navbar {
        position: relative;
        width: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        background: black;
        animation-name: slide-right;
        animation-duration: 0.5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }

    .navbar-items {
        position: fixed;
        margin-top: 8em;
        margin-left: 5%;
        width: 6%;
        visibility: hidden;
        animation-name: slide-items-right;
        animation-duration: 0.5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes slide-right {
        from {
            width: 0;
        }
        to {
            width: 15vw;
        }
    }

    @keyframes slide-items-right {
        from {
            margin-left: -15vw;
            visibility: hidden;
        }
        to {
            margin-left: 5%;
            visibility: visible;
        }
    }

    .project-navbar {
        position: relative;
        width: 15vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        background: black;
    }

    .project-navbar-items {
        position: fixed;
        margin-top: 8em;
        margin-left: 2%;
        width: 10%;
        margin-right: 3%;
        font-size: 18px;
    }

    .about-navbar-items {
        position: fixed;
        margin-top: 8em;
        margin-left: 4%;
        width: 10%;
        margin-right: 1%;
        font-size: 18px;
    }

    .logo {
        font-size: 48px;
        font-weight: bold;
        text-decoration: none;
        color: white;
        width: 100%;
    }

    .navlinks {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 3em;
    }

    .navlink {
        color: rgba(255,255,255,0.5);
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
    }

    .navlink.active {
        color: white;
    }

    .navlink:hover {
        color: white;
    }

    .project-description p{
        border-bottom: 1px solid white;
        margin: 0;
    }

    .project-description div{
        color: rgba(255,255,255,0.5);
        display: inline-block;
        margin-bottom: 2em;
    }
}


@media only screen and (max-width: 950px) {
    .navbar, .project-navbar {
        height: 100%;
        display: flex;
        z-index: 1;
        background: black;
        width: 100%;
        justify-content: space-between;
    }

    .navbar-items {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: space-between;
    }

    .navlinks {
        display: flex;
        justify-content: space-between;
        margin-right: 2em;
    }

    .navlink {
        text-decoration: none;
        color: rgba(255,255,255,0.5);
        margin-left: 1em;
        margin-right: 1em;
    }

    .navlink.active {
        color: white;
    }

    .navlink:hover {
        color: white;
    }

    .logo {
        color: white;
        text-decoration: none;
        margin-left: 2em;
    }

    .nav-footer {
        display: none;
    }
}