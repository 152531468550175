@font-face {
  font-family: Cairo-Custom;
  font-display: block;
  src: url('../font/Cairo-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Cairo-Custom';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0,0,0,0.97);
  color:white;
  overflow-anchor: none;
  overflow-x: hidden;
}


#root {
  width: 100%;
  height: 100%;
  display: flex;
}